<template>
  <router-view/>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
export default {
  setup() {
    onMounted(() => {
      // var u = navigator.userAgent, app = navigator.appVersion;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      // if(isAndroid) {
      //   callNative.forbiddenRefresh(false)
      // }
    })
  }
}
</script>

<style>
</style>
