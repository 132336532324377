import { Toast } from 'vant'
import api from './api'
import axios from 'axios'
import router from '../router'

export function port(name, data) {
  return new Promise((resolve, reject) => {
    api[name](data).then(res => {
      if(res.data.msg == '登录已过期' || res.data.msg == '账号不存在' || res.data.msg == '账号已锁定') {
        Toast(res.data.msg)
        localStorage.removeItem('token')
        setTimeout(() => {
          router.push('/login')
        }, 2000);
        return
      }
      if(res.data.code == 1 || res.data.code == 200) {
        resolve (res)
      } else {
        Toast(res.data.msg)
      }
    })
  })
}

export function ports(name, data) {
  return new Promise((resolve, reject) => {
    api[name](data).then(res => {
      resolve (res)
    })
  })
}

export function getFile(data) {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', data.file)
    var configs = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    axios
      .post(
        api.imgUrl + '/ajax/upload/upload_images',
        formData,
        configs
      )
      .then(async (res) => {
        resolve(res)
      })
  })
}

export function getFiles(data) {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', data.file)
    var configs = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    axios
      .post(
        api.imgUrl + '/ajax/Qiniu/video',
        formData,
        configs
      )
      .then(async (res) => {
        resolve(res)
      })
  })
}

function imgUrl() {
  return api.imgUrl
}

export { imgUrl }