import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '../public/js/flexible'

import 'vant/lib/index.css'
import '../public/css/base.css'

import { Swipe, SwipeItem, Icon, Checkbox, Uploader, Switch, RadioGroup, Radio, CountDown, Rate, Overlay, Popup, Stepper, Picker, DatetimePicker, DropdownMenu, DropdownItem, Cascader, Loading, List } from 'vant'

createApp(App).use(store).use(router).use(Icon).use(Swipe).use(SwipeItem).use(Checkbox).use(Uploader).use(Switch).use(RadioGroup).use(Radio).use(CountDown).use(Rate).use(Overlay).use(Popup).use(Stepper).use(List).use(Picker).use(Loading).use(DatetimePicker).use(DropdownMenu).use(DropdownItem).use(Cascader).mount('#app')
