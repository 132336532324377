let subDomain = "";
const API_BASE_URL = "https://jncz220622.zhiju001.com";
// const API_BASE_URL = "https://manage.maopuyw.com"
const imgUrl = "https://jncz220622.zhiju001.com";
// const imgUrl = "https://manage.maopuyw.com"
import axios from "axios";
import qs from "qs"

const request = (_url, method, data) => {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.headers['token'] = localStorage.getItem('token') ? localStorage.getItem('token') : ''
  data = qs.stringify(data)
  if(method == 'get') {
    _url += '?' + data
  }
  let url = API_BASE_URL + subDomain + _url;
  return new Promise((resolve) => {
    axios[method](url, data).then(res => {
      resolve(res)
    })
  });
};

export default {
  request,
  imgUrl,
  
  // 登录
  login: (data) => request('/index/login/index', 'post', data),
  
  // 注册
  register: (data) => request('/index/register/save', 'post', data),
  
  // 轮播
  banner: (data) => request('/index/index/banner', 'get', data),

  // 用户信息
  userInfo: (data) => request('/index/user/info', 'get', data),
  
  // 地址列表
  addressList: (data) => request('/wechat/personal/address_list', 'get', data),
  
  // 添加地址
  addAddress: (data) => request('/wechat/personal/address_save', 'post', data),
  
  // 省市区
  provinces: (data) => request('/wechat/personal/regional_linkage', 'get', data),
  
  // 地址详情
  addressDet: (data) => request('/wechat/personal/address_details', 'get', data),
  
  // 修改地址
  addressUpd: (data) => request('/wechat/personal/address_update', 'post', data),
  
  // 修改登录密码
  updLogPsd: (data) => request('/wechat/personal/update_pass', 'post', data),
  
  // 修改支付密码
  updPayPsd: (data) => request('/wechat/personal/update_pay_password', 'post', data),
  
  // 修改个人信息
  editNews: (data) => request('/index/user/edit_info', 'post', data),
  
  // 申请店员
  applyClerk: (data) => request('/index/user/apply_service', 'post', data),
  
  // 上传
  uploadImg: (data) => request('/ajax/upload/upload_images', 'post', data),
  
  // 上传
  uploadVideo: (data) => request('/ajax/Qiniu/video', 'post', data),
  
  // 删除地址
  delAddress: (data) => request('/wechat/personal/address_delete', 'post', data),
  
  // 选择身份
  identity: (data) => request('/index/user/choose_type', 'get', data),
  
  // 标签列表
  tagList: (data) => request('/index/index/tag_list', 'get', data),
  
  // 选择标签
  selTag: (data) => request('/index/user/choose_tag', 'post', data),
  
  // 礼物列表
  giftList: (data) => request('/index/index/gift_list', 'get', data),
  
  // 赠送礼物
  giveGift: (data) => request('/index/order/give_gift', 'post', data),
  
  // 收藏列表
  collectList: (data) => request('/index/product/collect_list', 'get', data),
  
  // 店员列表
  productList: (data) => request('/index/product/list', 'get', data),
  
  // 店员详情
  productDet: (data) => request('/index/product/service_info', 'get', data),
  
  // 添加、取消收藏
  isCollect: (data) => request('/index/product/add_collect', 'post', data),
  
  // 排行榜
  rankingList: (data) => request('/index/user/rank', 'get', data),
  
  // 店员评价
  evalProduct: (data) => request('/index/product/service_evaluate', 'get', data),
  
  // 添加标签
  addTag: (data) => request('/index/index/add_tag', 'get', data),
  
  // 我的礼物
  myGift: (data) => request('/index/user/my_gift', 'get', data),
  
  // 我的订单
  orderList: (data) => request('/index/order/my_order', 'get', data),

  // 参数配置
  allocation: (data) => request('/index/index/site_config', 'get', data),

  // 创建订单
  submitOrder: (data) => request('/index/order/payment', 'post', data),

  // 热门收藏
  redCollect: (data) => request('/index/index/hot_collect', 'get', data),

  // 评价订单
  commentOrder: (data) => request('/index/order/comment', 'get', data),

  // 接单详情
  orderInfo: (data) => request('/index/order/order_info', 'get', data),

  // 发表消息
  commentNews: (data) => request('/index/user/save_communication', 'post', data),

  // 发表消息
  newsList: (data) => request('/index/user/communication_list', 'get', data),

  // 消息详情
  newsDet: (data) => request('/index/user/communication_info', 'get', data),

  // 项目列表
  xmList: (data) => request('/index/product_cate/index', 'get', data),

  // 添加项目
  addXm: (data) => request('/index/product_cate/save', 'post', data),

  // 添加项目
  saveItem: (data) => request('/index/product/save', 'post', data),

  // 我的服务
  saveList: (data) => request('/index/product/my_service', 'get', data),

  // 我的标签
  myTag: (data) => request('/index/user/tags', 'get', data),

  // 接单
  takeOrder: (data) => request('/index/order/take_order', 'post', data),

  // 续时
  addTime: (data) => request('/index/order/add_time', 'post', data),

  // 更新位置
  updSite: (data) => request('/index/user/save_location', 'get', data),

  // 找回密码
  lookPsd: (data) => request('/index/login/forget', 'post', data),

  // 账号注销
  logout: (data) => request('/index/user/account_logout', 'get', data),

  // 奖品列表
  luckyList: (data) => request('/index/lucky/index', 'get', data),

  // 抽奖
  lucky: (data) => request('/index/lucky/random', 'get', data),

  // 取消订单
  cancelOrder: (data) => request('/index/order/cancel_order', 'get', data),

  // 公告
  noticeList: (data) => request('/index/index/notice', 'get', data),

  // 公告详情
  noticeDet: (data) => request('/index/index/notice_info', 'get', data),

  // 用户发布
  issueUser: (data) => request('/index/demand/save', 'post', data),

  // 我的发布
  myIssue: (data) => request('/index/demand/index', 'get', data),

  // 接单发布订单
  issueTakeOrder: (data) => request('/index/demand/take_order', 'post', data),

  // 需求详情
  issueDet: (data) => request('/index/demand/info', 'get', data),

  // 支付接单人
  issuePay: (data) => request('/index/demand/confirm_order', 'post', data),

  // 接单人信息
  dyNews: (data) => request('/index/index/get_service_info', 'get', data),

  // 短信
  notePsd: (data) => request('/index/index/send', 'get', data),

  // 实名认证
  autonym: (data) => request('/index/user/realname_auth', 'post', data)
}