<template>
<van-swipe class="my-swipe" :autoplay="3000" indicator-color="var(--bc)">
  <van-swipe-item v-for="(item, index) in bannerList" :key="item.id">
    <img :src="imgUrl+item.img" alt="">
  </van-swipe-item>
</van-swipe>

<!-- 用户端 -->
<div class="index-yh" v-if="isUser">
  <div class="index-site">
    <div class="flex-layout">
      <input type="text" placeholder="请输入地址" v-model="site">
    </div>
    <div class="index-site-time" @click="popupTime = true">
      <p class="time">{{yyTime ? yyTime : '请选择'}} <span>（大概时间）</span></p>
    </div>
    <ul>
      <li>订单备注：
        <input type="text" placeholder="编辑个性订单备注" v-model="xmTitle">
      </li>
      <li>时长：<input type="number" placeholder="请输入时长" v-model="xmPrice">小时</li>
      <li @click="goEdit">服务项目：
        <input type="text" placeholder="可自行编辑添加" style="background-color: transparent;" disabled="true" v-model="xmItems">
        <van-icon name="arrow" style="line-height: 1.875rem" />
      </li>
    </ul>
    <p class="hint">仅能选择一小时后时间</p>
    <p class="btn" @click="issueUser">免费发布需求</p>
    <div class="index-nearbys">附近人有<span>{{nearby}}</span>名推荐官可以接单</div>
  </div>

  <ul class="index-nav">
    <li :class="[active == index ? 'active' : '']" v-for="(item, index) in navList" :key="item.id" @click="togNav(index)">
      <p>{{item.name}}</p>
      <p></p>
    </li>
  </ul>

  <ul class="index-second-list">
    <li :class="[second == index ? 'active' : '']" v-for="(item, index) in secondList" :key="item.id" @click="togSecond(index)">{{item.name}}</li>
  </ul>

  <div class="product-list">
    <template v-for="(item, index) in productList" :key="item.id">
      <product :product-only="item"></product>
    </template>
  </div>

  <p class="index-title">推荐附近推荐官</p>

  <div class="product-list">
    <template v-for="(item, index) in proRecList" :key="item.id">
      <product :product-only="item"></product>
    </template>
  </div>

  <p class="more" @click="goOffice(1)">查看更多附近推荐官</p>

  <p class="index-title">推荐好评推荐官</p>

  <ul class="index-second-list">
    <li :class="[second == index ? 'active' : '']" v-for="(item, index) in secondList" :key="item.id" @click="togSecond(index)">{{item.name}}</li>
  </ul>

  <div class="product-list">
    <template v-for="(item, index) in proGoodList" :key="item.id">
      <product :product-only="item"></product>
    </template>
  </div>

  <p class="more" @click="goOffice(2)">查看更多好评推荐官</p>
</div>

<!-- 店员端 -->
<div v-else>
  <div class="index-dy-news">
    <p class="wel">WELCOME</p>
    <div class="index-dy-content">
      <ul>
        <li>标题：
          <input type="text" placeholder="编辑个性标题签名" v-model="xmTitle">
        </li>
        <li @click="goEdit">服务项目：
          <input type="text" placeholder="可自行编辑添加" style="background-color: transparent;" disabled="true" v-model="xmItems">
          <van-icon name="arrow" style="line-height: 1.875rem" />
        </li>
        <li>设置价格：
          <input type="text" placeholder="自定义每小时标价" v-model="xmPrice">
        </li>
        <li>
          <div style="display: flex; align-items: center; height: 100%">昵称<input style="width: 20%; text-align: left; height: 100%; margin-left: .5rem;" type="text" placeholder="请输入昵称" v-model="xmName"></div>
          <div style="display: flex; align-items: center; height: 100%">身高<input style="width: 20%; text-align: left; height: 100%; margin-left: .5rem;" type="text" placeholder="请输入身高" v-model="xmHeight"></div>
        </li>
        <li>
          <div style="display: flex; align-items: center; height: 100%">体重<input style="width: 20%; text-align: left; height: 100%; margin-left: .5rem;" type="text" placeholder="请输入体重" v-model="xmWeight"></div>
          <div style="display: flex; align-items: center; height: 100%">年龄<input style="width: 20%; text-align: left; height: 100%; margin-left: .5rem;" type="number" placeholder="请输入年龄" v-model="xmAge"></div>
        </li>
        <li>简介 <input type="text" placeholder="请输入简介" style="text-align: left; margin-left: .5rem; height: 100%" v-model="xmSynopsis"></li>
        <li class="index-dy-upload">
          <p>上传主图照片：</p>
          <div>
            <van-uploader v-model="fileList" max-count="6" multiple="true" accept="image/*">
              <p class="flex-center">
                <img src="../../public/img/icon44.png" alt="">
              </p>
            </van-uploader>
          </div>
        </li>
        <li class="upload-video">
          <p>视频：<span v-if="fileVideo" @click="delVideo">删除</span></p>
          <div class="video-img" v-if="!fileVideo">
            <label for="video">
              <img src="../../public/img/icon8.png" alt="">
            </label>
            <input type="file" style="display: none;" accept="video" id="video" @change="getVideo">
          </div>
          <div class="video-img" v-else>
            <video :src="fileVideo" controls></video>
          </div>
        </li>
      </ul>
      <p class="btn" @click="saveItem">免费发布订单</p>
    </div>
    <p class="index-title">我发布的订单</p>

    <div class="product-list order-product">
      <div class="product-only">
        <div class="flex-center" v-if="saveList.image">
          <img :src="imgUrl + saveList.image" alt="">
        </div>
        <div class="product-only-new">
          <p>{{saveList.title}}</p>
          <p class="site" v-if="saveList.location"><span>{{saveList.location}}</span></p>
          <p class="price">¥{{saveList.price}}/小时</p>
          <div class="like">
            <van-icon name="like" />{{saveList.collect_count}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<tab-bar :active="0" v-if="isUser"></tab-bar>
<tab-bars :active="0" v-else></tab-bars>

<van-popup v-model:show="popupTime" position="bottom">
  <van-datetime-picker v-model="currentDate" type="datetime" :min-date="minData" title="选择时间" @cancel="popupTime = false" @confirm="popConTime" />
</van-popup>

<van-overlay :show="loading">
  <van-loading type="spinner" color="#1989fa" />
</van-overlay>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import product from '@/components/product'
import tabBar from '@/components/tabBar'
import tabBars from '@/components/tabBars'
import { port, imgUrl, getFile, getFiles, ports } from '../service/port'
import myBMap from "../service/bmap"
import { Toast } from 'vant'
export default {
  components: {
    product,
    tabBar,
    tabBars
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      imgUrl: imgUrl(),
      bannerList: [],
      navList: [{
        id: 1,
        name: '线下推荐官'
      }, {
        id: 2,
        name: '线上推荐官'
      }],
      secondList: [],
      productList: [],
      proRecList: [],
      proGoodList: [],
      currentDate: new Date(),
      minData: new Date(),
      active: 0,
      second: 0,
      site: '',
      show: true,
      isUser: '',
      xmTitle: '',
      xmItems: '',
      xmPrice: '',
      fileList: [],
      xmItemId: '',
      saveList: '',
      intro: '',
      nearby: 0,
      popupTime: false,
      yyTime: '',
      loading: true,
      lcyTime: '',
      fileVideo: '',
      videos: '',
      addressId: '',
      files: '',
      xmSynopsis: '',
      xmAge: '',
      xmWeight: '',
      xmHeight: '',
      xmName: ''
    })

    const delVideo = () => {
      state.fileVideo = ''
    }

    const getVideo = (e) => {
      var reader = new FileReader();
      state.videos = e.target.files[0]
      reader.onload = (e) => {
        state.fileVideo = e.target.result
      }
      reader.readAsDataURL(e.target.files[0])
    }

    const togNav = (index) => {
      state.active = index
      productList(1, {
        type: index != 1 ? 2 : 1,
        tag: state.secondList.length ? state.secondList[state.second].id : ''
      })
      productList(2, {
        type: index != 1 ? 2 : 1,
        is_rec: 1
      })
      productList(3, {
        type: index != 1 ? 2 : 1,
        is_good: 1,
        tag: state.secondList[index] ? state.secondList[index].id : ''
      })
    }

    const popConTime = (time) => {
      state.yyTime = `${formatNumber(time.getMonth() + 1)}月${formatNumber(time.getDate())}日 ${formatNumber(time.getHours())}:${formatNumber(time.getMinutes())}`
      state.popupTime = false
      state.lcyTime = formatTime(new Date(time))
    }

    const formatTime = date => {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()

      return `${[year, month, day].map(formatNumber).join('/')} ${[hour, minute, second].map(formatNumber).join(':')}`
    }

    const formatNumber = n => {
      n = n.toString()
      return n[1] ? n : `0${n}`
    }

    const togSecond = (index) => {
      state.second = index
      productList(1, {
        type: state.active != 1 ? 2 : 1,
        tag: state.secondList[index].id
      })
      productList(2, {
        type: state.active != 1 ? 2 : 1,
        is_rec: 1
      })
      productList(3, {
        type: state.active != 1 ? 2 : 1,
        is_good: 1,
        tag: state.secondList[index].id
      })
    }

    const goOffice = (type) => {
      router.push({ path: '/officerList', query: { type } })
    }

    const bMap = async() => {
      myBMap.init().then(BMap => {
        let myCity = new BMap.LocalCity()
        myCity.get(
          result => {
            let geoc = new BMap.Geocoder();
            geoc.getLocation(result.center, res => {
              console.log(res)
              let { city, district } = res.addressComponents
              let { lng, lat } = res.point
              let newCity = `${city} ${district}`
              let newLon = `${lng},${lat}`
              updSite(newCity, newLon)
            });
          },
          { enableHighAccuracy: true }
        );
      });
    }

    const bMaps = async() => {
      myBMap.init().then(BMap => {
        let myCity = new BMap.LocalCity()
        myCity.get(
          result => {
            let geoc = new BMap.Geocoder();
            geoc.getLocation(result.center, res => {
              let { city, district } = res.addressComponents
              state.site = `${city}-${district}`
            });
          },
          { enableHighAccuracy: true }
        );
      });
    }

    const updSite = async(city, lon_lat) => {
      let str = await port('updSite', {
        city,
        lon_lat
      })
    }

    const init = async() => {
      if(localStorage.getItem('xmListName')) {
        state.xmItems = localStorage.getItem('xmListName')
        state.xmItemId = localStorage.getItem('xmList')
        localStorage.removeItem('xmListName')
        localStorage.removeItem('xmList')
      }
      if(localStorage.getItem('xmArr')) {
        let { items, xmPrice, xmTitle, site, yyTime, lcyTime } = JSON.parse(localStorage.getItem('xmArr'))
        state.xmTitle = xmTitle
        state.xmPrice = xmPrice
        state.site = site
        state.yyTime = yyTime
        state.lcyTime = lcyTime
        localStorage.removeItem('xmArr')
      }
      state.loading = false
      let res = await port('userInfo')
      localStorage.setItem('isUser', res.data.data.type == 1 ? true : false)
      state.isUser = localStorage.getItem('isUser') == 'true' ? true : false
      bMap()
    }

    const productList = async(type, data) => {
      let res = await port('productList', data)
      let arr = []
      if(res.data.data.data.length) {
        res.data.data.data.map(item => {
          if(item.product) {
            arr.push(item)
          }
        })
      }
      if(type == 1) {
        state.productList = arr
      } else if(type == 2) {
        console.log(arr)
        let str = []
        if(arr.length > 4) {
          arr.map((item, index) => {
            if(index < 4) {
              str.push(item)
            }
          })
        } else {
          str = arr
        }
        state.proRecList = str
      } else {
        let str = []
        if(arr.length > 4) {
          arr.map((item, index) => {
            if(index < 4) {
              str.push(item)
            }
          })
        } else {
          str = arr
        }
        state.proGoodList = str
      }
    }

    // 标签列表
    const tagList = async() => {
      let res = await port('tagList')
      state.secondList = res.data.data
      productList(1, {
        type: 2,
        tagList: state.secondList[state.second] ? state.secondList[state.second].id : ''
      })
      productList(2, {
        type: 2,
        is_rec: 1
      })
      productList(3, {
        type: 2,
        tagList: state.secondList[state.second] ? state.secondList[state.second].id : '',
        is_good: 1
      })
    }

    const banner = async() => {
      let res = await port('banner')
      state.bannerList = res.data.data
    }

    // 服务项目
    const goEdit = () => {
      let { xmTitle, xmItems, xmPrice, site, yyTime, lcyTime } = state
      let arr = {
        xmTitle,
        items: xmItems,
        xmPrice,
        site,
        yyTime,
        lcyTime
      }
      localStorage.setItem('xmArr', JSON.stringify(arr))
      router.push({ path: '/editProject' })
    }

    const saveList = async() => {
      let res = await port('saveList')
      console.log(res)
      state.saveList = res.data.data.product
    }

    // 店员上传订单
    const saveItem = async() => {
      let { xmTitle, xmItemId, xmPrice, fileList, xmItems, fileVideo, xmSynopsis, xmHeight, xmWeight, xmName, xmAge } = state
      if(!xmTitle || !xmItemId || !xmPrice || !xmItems || !xmSynopsis || !xmHeight || !xmWeight || !xmName || !xmAge) {
        Toast('请完善信息')
        return
      }
      let imgs = ''
      let video = ''
      if(fileList.length) {
        imgs = await uploads(fileList)
      }
      if(!imgs) {
        Toast('请上传主图照片')
        return
      }
      state.loading = true
      if(fileVideo) {
        let str = await getFiles({
          file: state.videos
        })
        if(str.data.code == 200) {
          state.loading = false
          video = str.data.data.path
        } else {
          state.loading = false
        }
      }
      let res = await ports('saveItem', {
        title: xmTitle,
        price: xmPrice,
        items: xmItemId,
        imgs,
        video,
        age: xmAge,
        weight: xmWeight,
        nickname: xmName,
        height: xmHeight,
        jianjie: xmSynopsis
      })
      Toast(res.data.msg)
      state.loading = false
      if(res.data.code == 1 || res.data.code == 200) {
        state.xmTitle = '',
        state.xmPrice = '',
        state.xmItemId = '',
        state.xmItems = '',
        state.fileList = [],
        imgs = ''
        state.fileVideo = ''
        state.videos = ''
        saveList()
      }
    }

    // 多图上传
    const uploads = (files) => {
      let arr = []
      return new Promise((resolve, reject) => {
        files.map(async (v, i) => {
          let str = await getFile({
            file: v.file
          })
          if(str.data.code == 0) {
            arr.push(str.data.data.src)
            if (files.length == arr.length) {
              resolve(arr.join(','))
            }
          }
        })
      })
    }

    // 用户上传
    const issueUser = async() => {
      let { site, yyTime, xmTitle, xmItems, xmItemId, lcyTime, xmPrice, addressId } = state
      if(!site || !yyTime || !xmTitle || !xmItems || !xmPrice) {
        Toast('请完善信息')
        return
      }
      if(xmPrice < 3) {
        Toast('时长不能低于3小时')
        return
      }
      localStorage.removeItem('addressId')
      let res = await port('issueUser', {
        start_time: lcyTime,
        address: site,
        cate_id: xmItemId,
        count: xmPrice,
        title: xmTitle
      })
      Toast(res.data.msg)
      state.site = ''
      state.xmPrice = ''
      state.yyTime = ''
      state.lcyTime = ''
      state.xmTitle = ''
      state.xmItems = ''
    }

    const goAddress = () => {
      router.push({ path: '/addressList', query: { isBack: true } })
    }

    const nearbyPeople = async() => {
      let res = await port('productList', {
        nearby: 1
      })
      console.log(res)
      state.nearby = res.data.data.data.length
    }

    const addressDet = async(id) => {
      let res = await port('addressDet', { id })
      let { data, area } = res.data
      state.addressId = id
      state.site = `${area.prov.area_name}${area.city.area_name}${area.county.area_name}${data.details}`
    }

    onMounted(async () => {
      if(!localStorage.getItem('token')) {
        router.push('/login')
        return
      }
      if(localStorage.getItem('addressId')) {
        addressDet(localStorage.getItem('addressId'))
      }
      init()
      banner()
      tagList()
      saveList()
      togNav()
      nearbyPeople()
    })

    return {
      ...toRefs(state),
      togNav,
      togSecond,
      goOffice,
      bMap,
      goEdit,
      saveItem,
      bMaps,
      popConTime,
      issueUser,
      goAddress,
      getVideo,
      delVideo
    }
  }
}
</script>

<style lang="scss" scoped>
.index-yh {
  margin-bottom: 3.75rem;
}
.my-swipe {
  // height: 12.5rem;
  .van-swipe-item {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
}
.index-site {
  width: calc(100% - 2.125rem);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
  background-color: var(--cl);
  border-radius: .5rem;
  padding: 0 1.4375rem;
  position: relative;
  top: -2.5rem;
  left: 1.0625rem;
  .flex-layout {
    height: 2.375rem;
    font-size: 0.8125rem;
    // margin-top: 0.5rem;
    border-bottom: 1px solid var(--f1);
    .index-site-rgt {
      font-size: 0.75rem;
      color: var(--c6);
      display: flex;
      align-items: center;
      .flex-center {
        width: 0.8125rem;
        height: 0.8125rem;
      }
    }
  }
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.375rem;
      // margin-top: 0.5rem;
      border-bottom: 1px solid var(--f1);
      input {
        height: 100%;
        text-align: right;
      }
    }
  }
  .index-site-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;
    font-size: 0.8125rem;
    border-bottom: 1px solid var(--f1);
    // margin-top: 0.6875rem;
    span {
      font-size: 0.5625rem;
    }
  }
  .hint {
    font-size: 0.75rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    color: var(--cc);
  }
  .btn {
    height: 1.875rem;
  }
  .index-nearby {
    font-size: 0.75rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.index-nav {
  display: flex;
  font-size: 0.9375rem;
  padding: 0.8125rem 0;
  font-size: .8rem;
  li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    p:last-child {
      width: 1.625rem;
      height: 0.1875rem;
      border-radius: 0.125rem;
      background-color: transparent;
      margin-top: .5rem;
    }
    &.active {
      p:last-child {
        background-color: var(--bc);
      }
    }
    &:first-child {
      p:first-child {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
}
.index-second-list {
  padding: 0 0.9375rem 1.125rem;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  overflow: auto;
  li {
    padding: 0 .5rem;
    height: 1.625rem;
    border-radius: 0.9375rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--cc);
    border: 1px solid var(--cc);
    margin: 0 .25rem;
    display: inline-block;
    line-height: 1.625rem;
    text-align: center;
    &.active {
      border: 1px solid var(--c3);
      color: var(--c3);
    }
  }
}
.product-list {
  padding: 0 0.9375rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.index-title {
  font-size: 0.9375rem;
  height: 2.5rem;
  padding: 0 1.375rem;
  display: flex;
  align-items: center;
}
.index-menu {
  position: relative;
  top: -1.5625rem;
}
.more {
  height: 2.375rem;
  font-size: 0.75rem;
  margin: 0.75rem auto;
  width: calc(100% - 5.125rem);
  border: 1px solid var(--c97);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-dy-news {
  position: relative;
  top: -6rem;
  width: calc(100% - 1.875rem);
  left: 0.9375rem;
  .wel {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--cl);
    text-align: center;
    margin-bottom: 0.625rem;
  }
  .index-dy-content {
    background-color: var(--cl);
    border-radius: .5rem;
    padding: 1.5625rem 1.4375rem;
    ul {
      font-size: 0.8125rem;
      li {
        height: 3.0625rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid var(--ce);
        }
        &.site {
          font-size: 0.9375rem;
          div {
            font-size: 0.75rem;
            color: var(--c6);
            display: flex;
            align-items: center;
            .flex-center {
              width: 0.8125rem;
              height: 0.8125rem;
            }
          }
        }
        input {
          flex: 1;
          text-align: right;
        }
        .van-icon {
          width: 2.5rem;
          text-align: center;
        }
        &.index-dy-upload {
          height: inherit;
          display: block;
          p {
            line-height: 2.5rem;
          }
          >div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 1.25rem 0;
            .flex-center {
              width: 4rem;
              height: 4rem;
            }
          }
        }
        &.upload-video {
          height: auto;
          display: block;
          padding: .5rem 0;
          p {
            line-height: 2.625rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          video {
            width: 9.375rem;
          }
          .video-img {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 4.25rem;
              height: 4.25rem;
            }
          }
        }
      }
    }
  }
  .product-list {
    padding: 0;
  }
}
.order-product{
.product-only {
  width: 8.6875rem;
  background-color: var(--cl);
  border-radius: .5rem;
  font-size: 0.75rem;
  margin-bottom: 1.125rem;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1), 0px 1px 11px 0px rgba(0,0,0,0.1);
  .flex-center {
    width: 8.6875rem;
    height: 7.5625rem;
    img {
      border-radius: .5rem .5rem 0 0;
    }
  }
  .product-only-new {
    padding: .5rem;
    display: flex;
    flex-direction: column;
    .site {
      span {
        height: 0.9375rem;
        font-size: 0.5625rem;
        padding: 0 .2rem;
        background-color: var(--ec);
      }
      margin: .2rem 0;
    }
    .price {
      font-size: 0.625rem;
    }
    .like {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 0.625rem;
      color: var(--c9);
      .van-icon {
        color: var(--rd);
      }
    }
  }
}
}
#app {
  position: relative;
  .turntable {
    width: 300px;
    height: 300px;
    margin: 1.25rem 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .turntable-name {
    position: absolute;
    left: 10px;
    top: 20px;
    width: calc(100% - 20px);
    font-size: 14px;
    text-align: center;
    color: var(--rd);
  }
  .turntable-img {
    position: relative;
    left: calc(50% - 60px / 2);
    top: 60px;
    width: 60px;
    height: 60px;
    img {
      display: inline-block;
      width: 60%;
      height: 60%;
    }
  }
  .pointer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    // background-image: url('http://demo.sc.chinaz.net/Files/DownLoad/webjs1/201803/jiaoben5789/images/start.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.index-nearbys {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  span {
    color: var(--bc4);
  }
}
.popup-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  font-size: 1rem;
}
</style>
