<template>
  <ul class="tab-bar">
    <router-link :class="['nav-list-item',active == index ? 'active' : '']" :to="item.url" v-for="(item, index) in navList" :key="item.id">
      <p class="flex-center">
        <img :src="active == index ? item.imgs : item.img" alt="">
      </p>
      <span>{{item.name}}</span>
    </router-link>
  </ul>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
export default {
  props: {
    active: {
      type: Number
    }
  },
  setup() {
    const state = {
      navList: [{
        id: 1,
        name: '首页',
        url: 'home',
        img: require('../../public/img/index.png'),
        imgs: require('../../public/img/index-active.png')
      },{
        id: 2,
        name: '收藏',
        url: 'collect',
        img: require('../../public/img/collect.png'),
        imgs: require('../../public/img/collect-active.png')
      },{
        id: 3,
        name: '消息',
        url: 'myNews',
        img: require('../../public/img/news.png'),
        imgs: require('../../public/img/news-active.png')
      },{
        id: 4,
        name: '我的',
        url: 'profile',
        img: require('../../public/img/profile.png'),
        imgs: require('../../public/img/profile-active.png')
      }]
    }

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-bar {
  display: flex;
  align-items: center;
  background: var(--cl);
  height: 3.0625rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 0.5625rem;
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.16);
  .nav-list-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .flex-center {
      width: 1.25rem;
      height: 1.25rem;
    }
    &.active {
      color: var(--bc);
    }
  }
}
</style>