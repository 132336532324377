<template>
  <div class="product-only" @click="goDet(productOnly.user.id)">
    <div class="flex-center">
      <img :src="imgUrl + productOnly.product.image" alt="">
    </div>
    <div class="product-only-new">
      <p>{{productOnly.product.title}}</p>
      <p class="site" v-if="productOnly.user"><span>{{productOnly.user.location}}</span></p>
      <p class="price">¥{{productOnly.product.price}}/小时</p>
      <div class="like">
        <van-icon name="like" />{{productOnly.product.collect_count}}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { imgUrl } from '../service/port'
export default {
  props: {
    productOnly: {
      type: Object
    },
    types: {
      type: Number
    }
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      imgUrl: imgUrl()
    })

    const goDet = (id) => {
      router.push({ path: 'detail', query: { id } })
    }

    return {
      goDet,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped lang="scss">
.product-only {
  background-color: var(--cl);
  border-radius: .5rem;
  font-size: 0.75rem;
  margin-bottom: 1.125rem;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1), 0px 1px 11px 0px rgba(0,0,0,0.1);
  .flex-center {
    width: 8.6875rem;
    height: 7.5625rem;
    img {
      border-radius: .5rem .5rem 0 0;
    }
  }
  .product-only-new {
    padding: .5rem;
    display: flex;
    flex-direction: column;
    .site {
      span {
        height: 0.9375rem;
        font-size: 0.5625rem;
        padding: 0 .2rem;
        background-color: var(--ec);
      }
      margin: .2rem 0;
    }
    .price {
      font-size: 0.625rem;
    }
    .like {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 0.625rem;
      color: var(--c9);
      .van-icon {
        color: var(--rd);
      }
    }
  }
}
</style>
