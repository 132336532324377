import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/index.vue'

const routes = [
  {
    path: '/',
    component: Index
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/collect',
    name: 'collect',
    component: () => import(/* webpackChunkName: "about" */ '../views/collect.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/profile.vue')
  },
  {
    path: '/updPsd',
    name: 'updPsd',
    component: () => import(/* webpackChunkName: "about" */ '../views/updPsd.vue')
  },
  {
    path: '/safetyCenter',
    name: 'safetyCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/safetyCenter.vue')
  },
  {
    path: '/identity',
    name: 'identity',
    component: () => import(/* webpackChunkName: "about" */ '../views/identity.vue')
  },
  {
    path: '/tag',
    name: 'tag',
    component: () => import(/* webpackChunkName: "about" */ '../views/tag.vue')
  },
  {
    path: '/gift',
    name: 'gift',
    component: () => import(/* webpackChunkName: "about" */ '../views/gift.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/applyClerk',
    name: 'applyClerk',
    component: () => import(/* webpackChunkName: "about" */ '../views/applyClerk.vue')
  },
  {
    path: '/hotCollect',
    name: 'hotCollect',
    component: () => import(/* webpackChunkName: "about" */ '../views/hotCollect.vue')
  },
  {
    path: '/addAddress',
    name: 'addAddress',
    component: () => import(/* webpackChunkName: "about" */ '../views/addAddress.vue')
  },
  {
    path: '/officerList',
    name: 'officerList',
    component: () => import(/* webpackChunkName: "about" */ '../views/officerList.vue')
  },
  {
    path: '/drawRecord',
    name: 'drawRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/drawRecord.vue')
  },
  {
    path: '/submitOrder',
    name: 'submitOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/submitOrder.vue')
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrder.vue')
  },
  {
    path: '/approve',
    name: 'approve',
    component: () => import(/* webpackChunkName: "about" */ '../views/approve.vue')
  },
  {
    path: '/rankingList',
    name: 'rankingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/rankingList.vue')
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import(/* webpackChunkName: "about" */ '../views/addressList.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  {
    path: '/publishComment',
    name: 'publishComment',
    component: () => import(/* webpackChunkName: "about" */ '../views/publishComment.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import(/* webpackChunkName: "about" */ '../views/share.vue')
  },
  {
    path: '/rechargeRecord',
    name: 'rechargeRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/rechargeRecord.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/recharge.vue')
  },
  {
    path: '/balanceDraw',
    name: 'balanceDraw',
    component: () => import(/* webpackChunkName: "about" */ '../views/balanceDraw.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "about" */ '../views/personal.vue')
  },
  {
    path: '/myGift',
    name: 'myGift',
    component: () => import(/* webpackChunkName: "about" */ '../views/myGift.vue')
  },
  {
    path: '/myEvaluate',
    name: 'myEvaluate',
    component: () => import(/* webpackChunkName: "about" */ '../views/myEvaluate.vue')
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import(/* webpackChunkName: "about" */ '../views/ranking.vue')
  },
  {
    path: '/orderReceivingDet',
    name: 'orderReceivingDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/orderReceivingDet.vue')
  },
  {
    path: '/editProject',
    name: 'editProject',
    component: () => import(/* webpackChunkName: "about" */ '../views/editProject.vue')
  },
  {
    path: '/myNews',
    name: 'myNews',
    component: () => import(/* webpackChunkName: "about" */ '../views/myNews.vue')
  },
  {
    path: '/newsDet',
    name: 'newsDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDet.vue')
  },
  {
    path: '/officerLists',
    name: 'officerLists',
    component: () => import(/* webpackChunkName: "about" */ '../views/officerLists.vue')
  },
  {
    path: '/lookPsd',
    name: 'lookPsd',
    component: () => import(/* webpackChunkName: "about" */ '../views/lookPsd.vue')
  },
  {
    path: '/orderDet',
    name: 'orderDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/orderDet.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import(/* webpackChunkName: "about" */ '../views/insurance.vue')
  },
  {
    path: '/demandMarket',
    name: 'demandMarket',
    component: () => import(/* webpackChunkName: "about" */ '../views/demandMarket.vue')
  },
  {
    path: '/luckyWheel',
    name: 'luckyWheel',
    component: () => import(/* webpackChunkName: "about" */ '../views/luckyWheel.vue')
  },
  {
    path: '/bankSet',
    name: 'bankSet',
    component: () => import(/* webpackChunkName: "about" */ '../views/bankSet.vue')
  },
  {
    path: '/myIssue',
    name: 'myIssue',
    component: () => import(/* webpackChunkName: "about" */ '../views/myIssue.vue')
  },
  {
    path: '/issueDet',
    name: 'issueDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/issueDet.vue')
  },
  {
    path: '/personalNew',
    name: 'personalNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/personalNew.vue')
  },
  {
    path: '/agreementList',
    name: 'agreementList',
    component: () => import(/* webpackChunkName: "about" */ '../views/agreementList.vue')
  },
  {
    path: '/agreementDet',
    name: 'agreementDet',
    component: () => import(/* webpackChunkName: "about" */ '../views/agreementDet.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
